import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={12}>
              © {new Date().getFullYear()} IMS
              <span className="d-none d-sm-inline-block">
                {" - Powered with "}
                <i className="mdi mdi-heart text-danger"></i>
                {" by Nouvelles Technologies "}
              </span>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  )
}

export default Footer
