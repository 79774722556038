//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"
export const POST_LOGIN = "/api/login_check"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

// PERSONNES
export const API_PERSONNE = "/api/personnes"
export const API_PERSONNE_GENRE = "/api/personnes-genres"
export const API_PERSONNE_TITRE = "/api/personnes-titres"
export const API_PERSONNE_ETAT_CIVIL = "/api/personnes-etat-civil"
export const API_PERSONNE_STATUT = "/api/statut-personnes"
export const API_PERSONNE_FINDSUIVI = "/api/suivi/find-personne"

// RESEAUX MD
export const API_RESEAUX_MD = "/api/reseaux-md"

// PROFILS
export const API_PROFIL = "/api/profils"

// STATUTS
