import { get, post, put, del } from "./api_helper"
import * as url from "./url_helper"

// RESEAUX MD
const getReseauMdList = async () => {
  return await get(url.API_RESEAUX_MD).then(response => response)
}

const ReseauxMdService = {
  getReseauMdList,
}
export default ReseauxMdService
