import React, { useState, useEffect } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"
import { Row, Col, Card, CardBody, Form, FormGroup, Alert } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import no_user from "../../assets/images/users/no-user-suivi.png"
import moment from "moment"

import PersonneService from "helpers/personne_helper"

const PersonneImport = props => {
  document.title = "IMS | Importer des personnes"

  const [pattern, setPattern] = useState("")
  const [searchList, setSearchList] = useState([])
  const [importList, setImportList] = useState([])
  const [errorSearch, setErrorSearch] = useState("")

  const hideError = () => {
    setErrorSearch("")
  }
  const handleSearchSuivi = e => {
    e.preventDefault()
    PersonneService.findSuiviPersonneList(pattern)
      .then(response => {
        if (response.data.length > 0) {
          setSearchList(response.data)
          setErrorSearch("")
        } else {
          setErrorSearch("Votre recherche n'a donné aucun résultat")
          setSearchList([])
        }
      })
      .catch(err => {
        setErrorSearch("Erreur non attendue !!")
        console.log(err)
      })
  }

  const handleAddImport = (suivi, e) => {
    e.preventDefault()
    suivi.status = "En attente"
    setImportList(prevList => [...prevList, suivi])
    setSearchList(prevList => prevList.filter(s => s.id !== suivi.id))
  }
  const handleRemoveImport = (suivi, e) => {
    e.preventDefault()
    suivi.status = ""
    setImportList(prevList => prevList.filter(s => s.id !== suivi.id))
    setSearchList(prevList => [...prevList, suivi])
  }

  const handleDoImport = e => {
    e.preventDefault()
    importList.forEach(i => {
      PersonneService.importPersonne(i)
        .then(response => {
          i.status = `OK - ${response.message}`
          setImportList(prevData => [...prevData.filter(s => s.id !== i.id), i])
        })
        .catch(error => {
          console.log(error)
          i.status = `NOK - ${error.response.data.description}`
          setImportList(prevData => [...prevData.filter(s => s.id !== i.id), i])
        })
    })
  }

  useEffect(() => {
    const breadcrumbItems = [
      { title: "Accueil", link: "#" },
      { title: "Personne", link: "#" },
      { title: "Import", link: "#" },
    ]
    props.setBreadcrumbItems("Import personne", breadcrumbItems)
  }, [props])

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col lg={5}>
                  <div className="mt-4">
                    <h5 className="font-size-14 mb-4">
                      <i className="mdi mdi-account-search text-primary me-1"></i>
                      Recherchez une personne par son nom ou prénom
                    </h5>
                    <Form className="row gx-3 gy-2 align-items-center">
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="specificSizeInputName"
                          placeholder="Saisir nom ou prénom"
                          onChange={e => {
                            setPattern(e.target.value)
                          }}
                        />
                      </div>
                      <div className="col-auto">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={handleSearchSuivi}
                        >
                          Rechercher
                        </button>
                      </div>
                    </Form>
                  </div>
                  {errorSearch !== "" && (
                    <Col lg={8}>
                      <Alert
                        color="danger"
                        role="alert"
                        className="mb-0 mt-2"
                        onClick={hideError}
                      >
                        {errorSearch}
                      </Alert>
                    </Col>
                  )}
                  {searchList.length !== 0 && (
                    <Row>
                      <Col lg={12}>
                        <Table className="table table-striped table-bordered table-sm mt-4">
                          <Thead>
                            <Tr>
                              <Th>&nbsp;</Th>
                              <Th>Id</Th>
                              <Th>Nom</Th>
                              <Th>Prénom</Th>
                              <Th>Date de naissance</Th>
                              <Th>&nbsp;</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {searchList.map(item => (
                              <Tr key={item.id}>
                                <Td>
                                  <img
                                    className="rounded-circle avatar-sm"
                                    src={
                                      item.photo !== "" ? item.photo : no_user
                                    }
                                    alt="Header Avatar"
                                  />
                                </Td>
                                <Th>{item.id}</Th>
                                <Th>{item.nom}</Th>
                                <Th>{item.prenom}</Th>
                                <Td>
                                  {moment(item.dateNaissance).format(
                                    "DD/MM/YYYY",
                                  )}
                                </Td>
                                <Td>
                                  <button
                                    type="submit"
                                    className="btn btn-success btn-sm"
                                    onClick={e => {
                                      handleAddImport(item, e)
                                    }}
                                  >
                                    Ajouter
                                  </button>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col lg={7} className="ms-lg-auto">
                  <div className="mt-5 mt-lg-4">
                    <h5 className="font-size-14 mb-4">
                      <i className="mdi mdi-upload text-primary me-2"></i>
                      Liste des personnes à importer
                    </h5>
                    {importList.length > 0 && (
                      <div className="row">
                        <Col sm={12}>
                          <Table className="table table-striped table-bordered table-sm mt-4">
                            <Thead>
                              <Tr>
                                <Th>&nbsp;</Th>
                                <Th>Id</Th>
                                <Th>Nom</Th>
                                <Th>Prénom</Th>
                                <Th>Date de naissance</Th>
                                <Th>Statut import</Th>
                                <Th>&nbsp;</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {importList.map(item => (
                                <Tr key={item.id}>
                                  <Td>
                                    <img
                                      className="rounded-circle avatar-sm"
                                      src={
                                        item.photo !== "" ? item.photo : no_user
                                      }
                                      alt="Header Avatar"
                                    />
                                  </Td>
                                  <Th>{item.id}</Th>
                                  <Th>{item.nom}</Th>
                                  <Th>{item.prenom}</Th>
                                  <Td>
                                    {moment(item.dateNaissance).format(
                                      "DD/MM/YYYY",
                                    )}
                                  </Td>
                                  <Td>{item.status}</Td>
                                  <Td>
                                    <button
                                      type="submit"
                                      className="btn btn-danger btn-sm"
                                      onClick={e => {
                                        handleRemoveImport(item, e)
                                      }}
                                    >
                                      Supprimer
                                    </button>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </Col>
                        <div className="col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                            onClick={handleDoImport}
                          >
                            Importer
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(connect(null, { setBreadcrumbItems })(PersonneImport))
