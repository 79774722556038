import { get, post, put, del } from "./api_helper"
import * as url from "./url_helper"

// PERSONNES
const getPersonneList = async () => {
  return await get(url.API_PERSONNE).then(response => response)
}

// FIND SUIVI PERSONNE
const findSuiviPersonneList = async nom => {
  return await post(`${url.API_PERSONNE_FINDSUIVI}`, { pattern: nom }).then(
    response => response,
  )
}

// DETAIL PERSONNE
const getPersonneEdit = async personneId => {
  return await get(`${url.API_PERSONNE}/${personneId}`).then(
    response => response,
  )
}
const getPersonneGenres = async () => {
  return await get(url.API_PERSONNE_GENRE).then(response => response)
}

const getPersonneTitres = async () => {
  return await get(url.API_PERSONNE_TITRE).then(response => response)
}

const getPersonneEtatCivils = async () => {
  return await get(url.API_PERSONNE_ETAT_CIVIL).then(response => response)
}

const getPersonneStatuts = async () => {
  return await get(url.API_PERSONNE_STATUT).then(response => response)
}

const updatePersonne = async personne => {
  return await put(`${url.API_PERSONNE}/${personne.id}`, personne).then(
    response => response,
  )
}

const importPersonne = async personne => {
  return await post(`${url.API_PERSONNE}/import`, personne).then(
    response => response,
  )
}

const PersonneService = {
  getPersonneList,
  getPersonneEdit,
  getPersonneGenres,
  getPersonneTitres,
  getPersonneEtatCivils,
  getPersonneStatuts,
  updatePersonne,
  findSuiviPersonneList,
  importPersonne,
}
export default PersonneService
