import React, { useState, useEffect } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import moment from "moment"

import { connect } from "react-redux"
import { Link } from "react-router-dom"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import PersonneService from "helpers/personne_helper"

const Dashboard = props => {
  document.title = "Accueil | IMS - Intranet Ministère de Secours"

  const initialState = []
  const [personnes, setPersonnes] = useState(initialState)
  const [doUpdate, setDoUpdate] = useState(true)

  useEffect(() => {
    const breadcrumbItems = [
      { title: "IMS", link: "#" },
      { title: "Accueil", link: "#" },
    ]
    props.setBreadcrumbItems("Accueil", breadcrumbItems)

    if (doUpdate) {
      PersonneService.getPersonneList().then(datas => {
        const personnes = datas
          .filter(p => p !== null)
          .map(p => ({
            ...{
              id: p.id,
              nom: p.nom,
              prenom: p.prenom,
              email: p.email,
              telephoneFixe: p.telephoneFixe,
              telephoneMobile: p.telephoneMobile,
              dateNaissance: moment(p.dateNaissance).format("DD/MM/YYYY"),
              addBtn: (
                <Link
                  to={`/personne-edit/${p.id}`}
                  className="btn btn-sm btn-outline-primary"
                >
                  Edit
                </Link>
                /* <i className="mdi mdi-account-edit-outline" /> */
              ),
            },
          }))
        setPersonnes(personnes)
      })
      setDoUpdate(false)
    }
  }, [doUpdate, props])

  const dataColumns = [
    {
      label: "Id",
      field: "id",
      sort: "asc",
      width: 50,
    },
    {
      label: "Nom",
      field: "nom",
      sort: "true",
      width: 150,
    },
    {
      label: "Prénom",
      field: "prenom",
      sort: "true",
      width: 150,
    },
    {
      label: "Email",
      field: "email",
      sort: "true",
      width: 200,
    },
    {
      label: "Téléphone fixe",
      field: "telephoneFixe",
      sort: "false",
      width: 100,
    },
    {
      label: "Téléphone mobile",
      field: "telephoneMobile",
      sort: "false",
      width: 100,
    },
    {
      label: "Date de naissance",
      field: "dateNaissance",
      sort: "true",
      width: 150,
    },
    {
      label: "Actions",
      field: "addBtn",
    },
  ]
  let dataTable = { columns: dataColumns, rows: personnes }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row>
                <Col className="col-10">
                  <Link to="/personne-edit/0" className="btn btn-primary">
                    <i className="mdi mdi-account-plus-outline"></i> Nouvelle
                    personne
                  </Link>
                </Col>
                <Col className="col-10 text-end">&nbsp;</Col>
              </Row>

              <MDBDataTable
                responsive
                striped
                bordered
                small
                data={dataTable}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="col-12"></Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard)
