import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"
import classnames from "classnames"
import moment from "moment"
import { Link } from "react-router-dom"
import { getLocalItem, setLocalItem } from "helpers/localstorage_helper"
import no_user from "../../assets/images/users/no-user-suivi.png"

import PersonneService from "helpers/personne_helper"
import ReseauxMdService from "helpers/reseau_md_helper"

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  CardTitle,
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
} from "reactstrap"
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation"

import * as yup from "yup"

const PersonneEdit = props => {
  //meta title
  document.title = "IMS | Edit Personne"

  const [customActiveTab, setCustomActiveTab] = useState("1")
  const [personne, setPersonne] = useState({})
  const [genres, setGenres] = useState({})
  const [titres, setTitres] = useState({})
  const [etatCivils, setEtatCivils] = useState({})
  const [statuts, setStatuts] = useState([])
  const [reseaux, setReseaux] = useState({})
  const [doUpdate, setDoUpdate] = useState(true)
  const [successMessage, setSuccess] = useState("")
  const [errorMessage, setError] = useState("")

  const { personneId } = useParams()

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
  }

  const handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    setPersonne({ ...personne, [name]: value })
  }
  const handleStatutChange = e => {
    console.log(e)
    setPersonne({ ...personne, statuts: e })
  }

  const handleUpdatePersonne = e => {
    e.preventDefault()
    PersonneService.updatePersonne(personne)
      .then(data => {
        console.log(data)
        setSuccess("OK successful")
      })
      .catch(e => {
        console.log(e)
        setError("NOK unsuccessful")
      })
  }

  const handleUpdateMembre = e => {
    e.preventDefault()
  }

  useEffect(() => {
    if (doUpdate) {
      const breadcrumbItems = [
        { title: "Accueil", link: "#" },
        { title: "Personne", link: "#" },
        { title: "Edit", link: "#" },
      ]
      props.setBreadcrumbItems("Edit personne", breadcrumbItems)

      PersonneService.getPersonneGenres().then(data =>
        setGenres(Object.values(data)),
      )
      PersonneService.getPersonneTitres().then(data =>
        setTitres(Object.values(data)),
      )

      PersonneService.getPersonneEtatCivils().then(data =>
        setEtatCivils(Object.values(data)),
      )

      PersonneService.getPersonneStatuts().then(data => {
        const items = data
          .filter(s => s !== null)
          .map(s => ({
            label: s.libelle,
            value: s.id,
            id: s.id,
            libelle: s.libelle,
          }))
        setStatuts([{ label: "Statuts", options: items }])
      })

      ReseauxMdService.getReseauMdList().then(datas => {
        const items = datas
          .filter(r => r !== null)
          .map(r => ({
            ...{
              id: r.id,
              nom: r.nom,
            },
          }))
        setReseaux(items)
      })

      if (personneId !== "0") {
        PersonneService.getPersonneEdit(personneId).then(datas => {
          let now = moment()

          let p = {
            id: datas.id,
            genre: datas.genre,
            titre: datas.titre,
            nom: datas.nom,
            prenom: datas.prenom,
            telephoneFixe: datas.telephoneFixe,
            telephoneMobile: datas.telephoneMobile,
            email: datas.email,
            adresse: datas.adresse,
            ville: datas.ville,
            codePostal: datas.codePostal,
            isFixeInvalide: !datas.isFixeValide,
            isMobileInvalide: !datas.isMobileValide,
            isEmailInvalide: !datas.isEmailValide,
            isAdresseInvalide: !datas.isAdresseValide,
            dateNaissance: datas?.dateNaissance,
            dateConversion: datas?.dateConversion,
            age: datas?.dateNaissance
              ? now.diff(moment(datas.dateNaissance), "years")
              : "",
            lieuNaissance: datas.lieuNaissance,
            nationalite: datas.nationalite,
            situationMatrimoniale: datas.situationMatrimoniale,
            profession: datas?.profession ?? "",
            formation: datas.formation,
            dateEntreeService: datas.dateEntreeService,
            dateFrequentation: datas.dateFrequentation,
            etatCivil: datas.etatCivil,
            isFondementTermine: datas?.isFondementTermine ?? false,
            reseauMdId: datas.reseauMd?.id,
            statuts: datas.statuts
              .filter(s => s !== null)
              .map(s => ({
                ...{
                  id: s.id,
                  libelle: s.libelle,
                  label: s.libelle,
                  value: s.id,
                },
              })),
            vientToujours: datas.vientToujours,
            isMembreTermine: datas.isMembreTermine,
            dateMembreOfficiel: datas.dateMembreOfficiel,
            photo: datas.photoFilepath,
          }
          setPersonne(p)
        })
      }
      setDoUpdate(false)
    }
  }, [props, personneId, doUpdate, personne, statuts])

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg="1">
            <img
              className="rounded avatar-lg"
              src={personne.photo !== "" ? personne.photo : no_user}
              alt="Header Avatar"
            />
          </Col>
          <Col lg="10">
            <div className="h3 text-primary mb-3 pt-4 pb-4">
              {personne.titre} {personne.prenom} {personne.nom}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            {errorMessage && errorMessage ? (
              <Alert color="danger">{errorMessage}</Alert>
            ) : null}
            {successMessage ? (
              <Alert color="success">{successMessage}</Alert>
            ) : null}
            <Card>
              <CardBody>
                <Nav tabs className="nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1")
                      }}
                    >
                      <span className="d-none d-sm-block">
                        Niveau I : Données Générales
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2")
                      }}
                    >
                      <span className="d-none d-sm-block">
                        Niveau II : Membre
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleCustom("3")
                      }}
                    >
                      <span className="d-none d-sm-block">
                        Niveau III : Ministère de Secours
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "4",
                      })}
                      onClick={() => {
                        toggleCustom("4")
                      }}
                    >
                      <span className="d-none d-sm-block">Audit</span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent
                  activeTab={customActiveTab}
                  className="p-3 text-muted"
                >
                  <TabPane tabId="1">
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={handleUpdatePersonne}
                    >
                      <Row>
                        <Col sm="6">
                          <Card>
                            <CardBody>
                              <CardTitle className="h4 text-primary mb-3">
                                Coordonnées
                              </CardTitle>
                              <Row>
                                <Col md="2">
                                  <div className="mb-3">
                                    <Label htmlFor="genre">Genre</Label>
                                    <select
                                      className="form-control"
                                      id="genre"
                                      name="genre"
                                      value={personne.genre}
                                      onChange={handleChange}
                                    >
                                      {Array.isArray(genres) &&
                                        genres.map((g, index) => (
                                          <option key={index} value={g}>
                                            {g}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <Label htmlFor="titre">Titre</Label>
                                    <select
                                      className="form-control"
                                      id="titre"
                                      name="titre"
                                      value={personne.titre}
                                      onChange={handleChange}
                                    >
                                      {Array.isArray(titres) &&
                                        titres.map((t, index) => (
                                          <option key={index} value={t}>
                                            {t}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div className="mb-3">
                                    <Label htmlFor="prenom">Prénom</Label>
                                    <AvField
                                      name="prenom"
                                      placeholder="Prénom"
                                      type="text"
                                      errorMessage="Saisir un prénom !!"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="prenom"
                                      value={personne.prenom}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div className="mb-3">
                                    <Label htmlFor="nom">Nom</Label>
                                    <AvField
                                      name="nom"
                                      placeholder="Nom"
                                      type="text"
                                      errorMessage="Saisir un nom !!"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="nom"
                                      value={personne.nom}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label htmlFor="adresse">
                                      Adresse postale
                                    </Label>
                                    <AvField
                                      name="adresse"
                                      placeholder="adresse"
                                      type="text"
                                      errorMessage="Merci de saisir une adresse"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="adresse"
                                      value={personne.adresse}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="form-check mb-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultChecked={
                                        personne.isAdresseInvalide
                                      }
                                      onChange={handleChange}
                                      id="isAdresseInvalide"
                                      name="isAdresseInvalide"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="isAdresseInvalide"
                                    >
                                      Adresse non valide
                                    </label>
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div className="mb-3">
                                    <Label htmlFor="ville">Ville</Label>
                                    <AvField
                                      name="ville"
                                      placeholder="ville"
                                      type="text"
                                      errorMessage="Merci de saisir une ville"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="ville"
                                      value={personne.ville}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <Label htmlFor="codePostal">CP</Label>
                                    <AvField
                                      name="codePostal"
                                      placeholder="Code postal"
                                      type="text"
                                      errorMessage="Merci de saisir un code postal"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="codePostal"
                                      value={personne.codePostal}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="4">
                                  <div className="mb-1">
                                    <Label htmlFor="telephoneMobile">
                                      Téléphone mobile
                                    </Label>
                                    <AvField
                                      name="telephoneMobile"
                                      placeholder="telephoneMobile"
                                      type="text"
                                      errorMessage="Merci de saisir un téléphone mobile"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="telephoneMobile"
                                      value={personne.telephoneMobile}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="form-check mb-3">
                                    <input
                                      className="form-check-input"
                                      name="isMobileInvalide"
                                      type="checkbox"
                                      defaultChecked={personne.isMobileInvalide}
                                      onChange={handleChange}
                                      id="isMobileInvalide"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="isMobileInvalide"
                                    >
                                      Mobile non valide
                                    </label>
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div className="mb-1">
                                    <Label htmlFor="telephoneFixe">
                                      Téléphone fixe
                                    </Label>
                                    <AvField
                                      name="telephoneFixe"
                                      placeholder="telephoneFixe"
                                      type="text"
                                      errorMessage="Merci de saisir un téléphone fixe"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="telephoneFixe"
                                      value={personne.telephoneFixe}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="form-check mb-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultChecked={personne.isFixeInvalide}
                                      onChange={handleChange}
                                      id="isFixeInvalide"
                                      name="isFixeInvalide"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="isFixeInvalide"
                                    >
                                      Fixe non valide
                                    </label>
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div className="mb-1">
                                    <Label htmlFor="email">Email</Label>
                                    <AvField
                                      name="email"
                                      placeholder="Email"
                                      type="text"
                                      errorMessage="Merci de saisir un adresse email"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="email"
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="form-check mb-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultChecked={personne.isEmailInvalide}
                                      onChange={handleChange}
                                      id="isEmailInvalide"
                                      name="isEmailInvalide"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="isEmailInvalide"
                                    >
                                      Email non valide
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col sm="6">
                          <Card>
                            <CardBody>
                              <CardTitle className="h4 text-primary mb-3">
                                Etat civil
                              </CardTitle>
                              <Row>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="dateNaissance">
                                      Date de naissance
                                    </Label>
                                    <AvField
                                      name="dateNaissance"
                                      placeholder="Date de naissance"
                                      type="Date"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="dateNaissance"
                                      value={moment(
                                        personne.dateNaissance,
                                      ).format("YYYY-MM-DD")}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <Label htmlFor="age">Age</Label>
                                    <AvField
                                      name="age"
                                      placeholder="Age"
                                      type="text"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="age"
                                      value={personne.age}
                                      onChange={handleChange}
                                      disabled={true}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="etatCivil">
                                      Etat civil
                                    </Label>
                                    <select
                                      className="form-control"
                                      id="etatCivil"
                                      name="etatCivil"
                                      value={personne.etatCivil}
                                      onChange={handleChange}
                                    >
                                      {Array.isArray(etatCivils) &&
                                        etatCivils.map((e, index) => (
                                          <option key={index} value={e}>
                                            {e}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                          <Card>
                            <CardBody>
                              <CardTitle className="h4 text-primary mb-3">
                                Vocation
                              </CardTitle>
                              <Row>
                                <Col md="4">
                                  <div className="mb-3">
                                    <Label htmlFor="profession">
                                      Profession
                                    </Label>
                                    <AvField
                                      name="profession"
                                      placeholder="Profession"
                                      type="text"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="profession"
                                      value={personne.profession}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div className="mb-3">
                                    <Label htmlFor="formation">Formation</Label>
                                    <AvField
                                      name="formation"
                                      placeholder="Formation"
                                      type="text"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="formation"
                                      value={personne.formation}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <Card>
                            <CardBody>
                              <CardTitle className="h4 text-primary mb-3">
                                Parcours spirituel
                              </CardTitle>
                              <Row>
                                <Col sm="4">
                                  <div className="mb-3">
                                    <Label htmlFor="dateConversion">
                                      Date de conversion
                                    </Label>
                                    <AvField
                                      name="dateConversion"
                                      placeholder="Date de naissance"
                                      type="Date"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="dateConversion"
                                      value={moment(
                                        personne.dateConversion,
                                      ).format("YYYY-MM-DD")}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </Col>
                                <Col sm="4">
                                  <div className="mb-3">
                                    <Label htmlFor="dateFrequentation">
                                      Fréquentateur depuis
                                    </Label>
                                    <AvField
                                      name="dateFrequentation"
                                      placeholder="Fréquentateur depuis"
                                      type="Date"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="dateFrequentation"
                                      value={moment(
                                        personne.dateFrequentation,
                                      ).format("YYYY-MM-DD")}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </Col>
                                <Col sm="4">
                                  <div className="form-check mb-3 mt-4">
                                    <input
                                      className="form-check-input"
                                      name="isFondementTermine"
                                      type="checkbox"
                                      defaultChecked={
                                        personne.isFondementTermine
                                      }
                                      onChange={handleChange}
                                      id="isFondementTermine"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="isFondementTermine"
                                    >
                                      Cours de fondement terminé
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="4">
                                  <div className="mb-3">
                                    <Label htmlFor="mentor">Mentor</Label>
                                    <AvField
                                      name="mentor"
                                      placeholder="Mentor"
                                      type="text"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="profession"
                                      value={personne.mentor}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <Label htmlFor="reseauxMdId">
                                      Réseau MD
                                    </Label>
                                    <select
                                      className="form-control"
                                      id="reseauMdId"
                                      name="reseauMdId"
                                      value={personne.reseauMdId}
                                      onChange={handleChange}
                                    >
                                      {Array.isArray(reseaux) &&
                                        reseaux.map((reseau, index) => (
                                          <option key={index} value={reseau.id}>
                                            {reseau.nom}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col sm="6">
                          <Card>
                            <CardBody>
                              <CardTitle className="h4 text-primary mb-3">
                                Statuts personne
                              </CardTitle>
                              <Row>
                                <Col md="8">
                                  <div className="mb-3">
                                    <Select
                                      value={personne.statuts}
                                      isMulti={true}
                                      onChange={handleStatutChange}
                                      options={statuts}
                                      classNamePrefix="select2-selection"
                                      isLoading={false}
                                      name="statuts"
                                      id="statuts"
                                    />
                                  </div>
                                </Col>
                                <Col sm="4">
                                  <div className="form-check mb-3 mt-4">
                                    <input
                                      className="form-check-input"
                                      name="vientToujours"
                                      type="checkbox"
                                      defaultChecked={personne.vientToujours}
                                      onChange={handleChange}
                                      id="vientToujours"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="vientToujours"
                                    >
                                      Vient toujours à l'église
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="2">
                          <Button color="primary" type="submit">
                            Enregistrer
                          </Button>
                        </Col>
                        <Col sm="2">
                          <Link to="/" className="btn btn-outline-primary">
                            Retour
                          </Link>
                        </Col>
                      </Row>
                    </AvForm>
                  </TabPane>
                  <TabPane tabId="2">
                    <AvForm
                      className="needs-validation"
                      onSubmit={handleUpdateMembre}
                    >
                      <Row>
                        <Col sm="6">
                          <Card>
                            <CardBody>
                              <CardTitle></CardTitle>
                              <Row>
                                <Col sm="5">
                                  <div className="form-check mb-3 mt-4">
                                    <input
                                      className="form-check-input"
                                      name="isMembreTermine"
                                      type="checkbox"
                                      defaultChecked={personne.isMembreTermine}
                                      onChange={handleChange}
                                      id="isMembreTermine"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="isMembreTermine"
                                    >
                                      Cours de membre terminés
                                    </label>
                                  </div>
                                </Col>
                                <Col sm="4">
                                  <div className="mb-3">
                                    <Label htmlFor="dateMembreOfficiel">
                                      Date membre officiel
                                    </Label>
                                    <AvField
                                      name="dateMembreOfficiel"
                                      placeholder="Date membre"
                                      type="Date"
                                      className="form-control"
                                      validate={{ required: { value: false } }}
                                      id="dateMembreOfficiel"
                                      value={moment(
                                        personne.dateMembreOfficiel,
                                      ).format("YYYY-MM-DD")}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="3">
                                  <div className="form-check mb-3 mt-4">
                                    <input
                                      className="form-check-input"
                                      name="isDime"
                                      type="checkbox"
                                      defaultChecked={personne.isDime}
                                      onChange={handleChange}
                                      id="isDime"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="isDime"
                                    >
                                      Dîmes ?
                                    </label>
                                  </div>
                                </Col>
                                <Col sm="7">
                                  <AvField
                                    className="mb-3"
                                    type="textarea"
                                    label="Commentaires"
                                    name="commentaires"
                                    id="commentaires"
                                    rows="5"
                                    placeholder="Commentaires"
                                    value={personne.commentaires}
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col sm="6"></Col>
                      </Row>
                    </AvForm>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        <CardText className="mb-0">
                          Etsy mixtape wayfarers, ethical wes anderson tofu
                          before they sold out mcsweeney's organic lomo retro
                          fanny pack lo-fi farm-to-table readymade. Messenger
                          bag gentrify pitchfork tattooed craft beer, iphone
                          skateboard locavore carles etsy salvia banksy hoodie
                          helvetica. DIY synth PBR banksy irony. Leggings
                          gentrify squid 8-bit cred pitchfork. Williamsburg banh
                          mi whatever gluten-free, carles pitchfork biodiesel
                          fixie etsy retro mlkshk vice blog. Scenester cred you
                          probably haven't heard of them, vinyl craft beer blog
                          stumptown. Pitchfork sustainable tofu synth chambray
                          yr.
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    <Row>
                      <Col sm="12">
                        <CardText className="mb-0">
                          Trust fund seitan letterpress, keytar raw denim
                          keffiyeh etsy art party before they sold out master
                          cleanse gluten-free squid scenester freegan cosby
                          sweater. Fanny pack portland seitan DIY, art party
                          locavore wolf cliche high life echo park Austin. Cred
                          vinyl keffiyeh DIY salvia PBR, banh mi before they
                          sold out farm-to-table VHS viral locavore cosby
                          sweater. Lomo wolf viral, mustache readymade
                          thundercats keffiyeh craft beer marfa ethical. Wolf
                          salvia freegan, sartorial keffiyeh echo park vegan.
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withRouter(connect(null, { setBreadcrumbItems })(PersonneEdit))
