export const getLocalItem = key => {
  const value = localStorage.getItem(key)
  return value ? JSON.parse(value) : undefined
}

export const setLocalItem = (key, value) => {
  if (value === null || value === undefined) {
    localStorage.removeItem(key)
    return
  }
  localStorage.setItem(key, value, { deep: true })
}
