import React from "react"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

const Breadcrumb = props => {
  const itemLength = (props.breadcrumbItems || []).length || 1

  return (
    <Row>
      <Col sm={6}>
        <div className="page-title-box">
          <h4>{props.title}</h4>
        </div>
      </Col>

      <Col sm={6}>
        <div className="state-information d-none d-sm-block">
          <div className="state-graph">
            <ol className="breadcrumb m-0 mr-1">
              {(props.breadcrumbItems || []).map((item, key) =>
                key + 1 === itemLength ? (
                  <li key={key} className="breadcrumb-item active">
                    {item.title}
                  </li>
                ) : (
                  <li key={key} className="breadcrumb-item">
                    <Link to="#">{item.title}</Link>
                  </li>
                ),
              )}
            </ol>
          </div>
        </div>
        {/* <div className="float-right d-none d-md-block">
      <Settingmenu />
    </div> */}
      </Col>
    </Row>
  )
}

const mapStatetoProps = state => {
  const Layout = state.Layout
  const BreadcrumbData = state.Breadcrumb
  return {
    layoutType: Layout.layoutType,
    title: BreadcrumbData.title,
    breadcrumbItems: BreadcrumbData.breadcrumbItems,
  }
}

export default connect(mapStatetoProps, {})(Breadcrumb)
