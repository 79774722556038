import { getLocalItem } from "helpers/localstorage_helper"

export const getAccessToken = () => {
  const obj = getLocalItem("authUser")

  if (obj?.accessToken) {
    return obj?.accessToken
  } else {
    return null
  }
}
